<!--

    订单申诉 页面

 -->
<template>
  <div class="Appeal">
    <div class="banner Appeal-width">
      <div class="Appeal-title">申诉原因</div>
      <div class="textarea">
        <textarea placeholder="请输入申诉原因" v-model="Appeal" maxlength="600"></textarea>
        <span>{{Appeal.length}}/600</span>
      </div>
      <div class="update-img">
        <div class="img">
          <ImageUpload :isShowTip="false" v-model="ImageUpload" :idcardImgShow="true" idcardImgTxt="上传照片" />
        </div>
      </div>
      <div class="submit" @click="GetAppeal">提交</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Appeal: "",
      Ids: "",
      ImageUpload: "",
    };
  },
  created() {
    // 当获取 不到 路由携带的参数的情况下 返回上一页
    if (!this.$route.params.id) {
      return this.$router.go(-1);
    }
    // 接受到 参数 去赋值
    this.Ids = this.$route.params.id;
  },
  methods: {
    GetAppeal() {
      if (!this.Appeal) return this.$modal.msgError("请填写申诉原因!");
      let url = "/app-api/hiring/service-order-appeal/create";
      
      let payload = {
        serviceOrderId: this.Ids,
        reason: this.Appeal,
      }
      if (this.ImageUpload) {
        payload.imgs = this.ImageUpload;
      }
      this.$axios
        .post(url, payload)
        .then((res) => {
          if (res.data.code === 0) {
            this.$modal.notify("提交成功！");
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.Appeal {
  padding: 30px 0;
  background: #f7f9fa;
  .Appeal-width {
    padding: 37px 122px 60px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
  }
  .Appeal-title {
    font-weight: 400;
    color: #000000;
    line-height: 42px;
    font-size: 36px;
    text-align: center;
  }
  .textarea {
    width: 100%;
    height: 261px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    margin-top: 60px;
    position: relative;
    span {
      position: absolute;
      bottom: 20px;
      right: 20px;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      font-size: 20px;
    }
    textarea {
      resize: none !important;
      width: 100%;
      height: 100%;
      padding: 30px;
      box-sizing: border-box;
      font-size: 20px;
      border-radius: 8px 8px 8px 8px;
      &::placeholder {
        font-weight: 400;
        color: #999999;
        line-height: 23px;
        font-size: 20px;
      }
    }
  }
  .update-img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    .component-upload-image {
      margin-right: 20px;
    }
  }
  .submit {
    width: 220px;
    height: 46px;
    background: #fc3737;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    font-size: 20px;
    margin: 100px auto 0;
    cursor: pointer;
  }
}
</style>
